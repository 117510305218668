$(document).ready(function() {
    $('.chartjs-render-monitor').addClass('shadow-class');

    $('.mouse_over_tab').mouseover(function() {
        $('#' + this.id).addClass('selected_tab_mouseover');
        $('.' + this.id).addClass('selected_tab_mouseover');


    }).mouseleave(function() {
        $('#' + this.id).removeClass('selected_tab_mouseover');
        $('.' + this.id).removeClass('selected_tab_mouseover');
    });

    $( ".mouse_over_tab" ).click(function() {
        $('.selected_tab').removeClass('selected_tab');
        $('#' + this.id).addClass('selected_tab');
        $('.' + this.id).addClass('selected_tab');
        $('.chart_hide').not('#' + this.id + '_chart').hide();
        $('#' + this.id + '_chart').show();
    });

    setNumeric();

    if (window.location.hash == "#_=_")
        window.location.hash = "";

    $('.box-review').matchHeight();
    $('.box-salary').matchHeight();
    $('.box-forum').matchHeight();
    $('.box-benefit').matchHeight();
    $('.box-news').matchHeight();
    $('.box-gallery').matchHeight();
    $('.company-profile-c').matchHeight();
    $('.box-testimonial').matchHeight();
    $('.box-contribution-col').matchHeight();
    $('.contribution-content-restriction-col').matchHeight();
    $('.last-comment-home').matchHeight();
    $('.pro-reviews-home').matchHeight();
    $('.box-resume-news').matchHeight();
    $('.company-name-last-contribution-news').matchHeight();
    $('.box-info-pro').matchHeight();
    $('.box-interview-process').matchHeight();
    $('.salary-guide-general-info1').matchHeight();
    $('.salary-guide-general-info2').matchHeight();
    $('.salary-guide-general-info3').matchHeight();
    $('.ulclub_header_title').matchHeight();
    $('.equal-height-element').matchHeight();




    $('.select_loc').bind('change', function () {
        var url = $(this).val(); // get selected value
        if (url) { // require a URL
            window.location = url; // redirect
        }
        return false;
    });

    //if ($("#select_analytic_period").length > 0) {
    //    postAnalitycsRequest(7);
    //}

    //filtru domenii firme
    $('.select_dom').bind('change', function () {
        var url = $(this).val(); // get selected value
        if (url) { // require a URL
            window.location = url; // redirect
        }
        return false;
    });
    //$("#select_analytic_period").on("change", function () {
    //    var select_analytic_period = $("#select_analytic_period").val();
    //    postAnalitycsRequest(select_analytic_period);
    // });

    //rating benefits
    rattingStarsBenefits();
    //rating stars reviews
    rattingStarsReviews();

    //reply\
    $('body').on('click','[name=button-reply]',function () {
        id = $(this).parent().parent().attr('id');
        $('[name=form-reply]').hide();
        if(authorized===true){
            $("#"+id).find('[name=form-reply]').show();
        }else{
            document.getElementById('buttonOpenLoginModal').click();
        }
    });
    $('body').on('click','button[name=close-form-reply]',function () {
        id = $(this).parent().parent().attr('id');
        $("#"+id).find('[name=form-reply]').hide();
    });
    $('body').on('click','button[name=save-reply]',function (event) {
        idElement = $(this).parent().parent().attr('id');
        textarea = $(this).parent().parent().find('textarea[name=comment-reply]');
        thisDiv = $(this).parent().parent();
        buttonDisabled=$(this);
        $('[name=success-sent-comment]').hide();
        var checked = thisDiv.find('[name=terms]').is(':checked');
        $('[name=not-checked]').hide();
        if(postId<0 ){
            postId = 0;
        }
        if(textarea.val().length > 0 && checked===true){
            if(buttonDisabled.attr("disabled")===false || typeof buttonDisabled.attr("disabled")==='undefined') {
                buttonDisabled.attr("disabled", true);
                axios.post(replyUrl, {
                    id: idElement,
                    comment: textarea.val(),
                    checked: checked,
                    postId: postId
                })
                    .then(function (response) {
                        buttonDisabled.attr("disabled", false);
                        textarea.val('');
                        thisDiv.find('[name=form-reply]').hide();
                        thisDiv.find('[name=success-sent-comment]').show();
                    })
                    .catch(function (error) {
                        $('[name=success-sent-comment]').hide();
                        buttonDisabled.attr("disabled", false);
                    });
            }
            else{
                event.stopPropagation();
            }
        }else{
            textarea.focus();
            thisDiv.find('[name=not-checked]').show();
        }
    });
    $('[pd-popup-close]').on('click', function(e)  {
        e.preventDefault();
        var targeted_popup_class = jQuery(this).attr('pd-popup-close');
        $('[pd-popup="' + targeted_popup_class + '"]').slideUp(250);
    });
    $("[vueCountId]").each(function () {
        showHideUsefulButtonIf0($(this))
    });
    $("[name=salaries-register-for-details] b").on('click',function(){
        $("#buttonOpenLoginModal").click();
        $('[name="withOutRefresh"]').remove();
    });
});
    function setNumeric() {
        $("[numeric]").keypress(function (e) {
            if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
                return false;
            }
        });
    }
    function rattingStarsReviews(){
        $('body').find('.ratings_stars').hover(
            // Handles the mouseover
            function () {
                var widget = $(this).parent().parent();
                $(this).prevAll().addBack().addClass('ratings_over');
                $(this).nextAll().removeClass('ratings_vote');
                var clicked_on = $(this).attr('class');
                var regexp = /([1-5]{1})/g;
                var value = clicked_on.match(regexp);
                var stat = set_status(value);
                $(widget).find('.msg_rating').text(stat);
            },
            // Handles the mouseout
            function () {
                var widget = $(this).parent().parent();
                $(this).prevAll().addBack().removeClass('ratings_over');
                set_votes(widget);
                var value = $(widget).find('.rating_value').val();
                var stat = set_status(value);
                $(widget).find('.msg_rating').text(stat);
            }
        );
        $('body').find('.ratings_stars').bind('click', function () {
            var star = this;
            var widget = $(this).parent().parent();
            var clicked_on = $(star).attr('class');

            $(this).prevAll().addBack().addClass('ratings_vote');
            $(this).nextAll().removeClass('ratings_vote');

            var regexp = /([1-5]{1})/g;
            var value = clicked_on.match(regexp);
            var stat = set_status(value);
            $(widget).find('.msg_rating').text(stat);
            $(widget).find('.rating_value').val(value);
        });
    }
    function rattingStarsBenefits(){
        $('body').find('.ratings_stars_b').hover(
            // Handles the mouseover
            function () {
                var widget = $(this).parent().parent();
                $(this).prevAll().addBack().addClass('ratings_over_b');
                $(this).nextAll().removeClass('ratings_vote_b');
                var clicked_on = $(this).attr('class');
                var regexp = /([1-5]{1})/g;
                var value = clicked_on.match(regexp);
                var stat = set_status(value);
                $(widget).find('.msg_rating').text(stat);
            },
            // Handles the mouseout
            function () {
                var widget = $(this).parent().parent();
                $(this).prevAll().addBack().removeClass('ratings_over_b');
                set_votes_b(widget);
                var value = $(widget).find('.rating_value').val();
                var stat = set_status(value);
                $(widget).find('.msg_rating').text(stat);
            }
        );
        $('body').find('.ratings_stars_b').bind('click', function () {
            var star = this;
            var widget = $(this).parent().parent();
            var clicked_on = $(star).attr('class');

            $(this).prevAll().addBack().addClass('ratings_vote_b');
            $(this).nextAll().removeClass('ratings_vote_b');

            var regexp = /([1-5]{1})/g;
            var value = clicked_on.match(regexp);
            var stat = set_status(value);
            $(widget).find('.msg_rating').text(stat);
            $(widget).find('.rating_value').val(value);
        });
    }
    function getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    function setCookie(name, value, days) {
        var d = new Date;
        d.setTime(d.getTime() + days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    function deleteCookie(name) { setCookie(name, '', -1); }

    function openPopupIfExpiredSession(namePopup , timeHours){
        var hours = timeHours; // Reset when storage is more than 24hours
        var now = new Date().getTime();
        var setupTime = getCookie(namePopup);
        hours = hours * 60 * 60 * 1000;
        if (setupTime == null) {
            setCookie(namePopup , now ,hours);
            openPopup(namePopup);
        } else {
            if (now - setupTime > hours) {
                setCookie(namePopup , now ,hours);
                openPopup(namePopup);
            }
        }
    }
    function openPopup(targeted_popup_class){
        $('[pd-popup="' + targeted_popup_class + '"]').slideDown(250);
    }
    function dropzoneElement(elementId ,inputId, classStyle , preventDefault) {
        preventDefault = preventDefault || null;
        var dropzone = document.getElementById(elementId);
        var dropzoneAnimate = document.getElementById(inputId);
        if(preventDefault === true){
            dropzone.ondrop = function(e) {
                e.preventDefault();
            };
        }
        dropzoneAnimate.ondrop = function(e) {
            $('#'+elementId).removeClass(classStyle);
        };
        dropzoneAnimate.ondragover = function(e){
            $('#'+elementId).addClass(classStyle);
            return false;
        };
        dropzoneAnimate.ondragleave = function(e){
            $('#'+elementId).removeClass(classStyle);
            return false;
        };
    }
    function nl2br (str, is_xhtml) {
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
    function addDotThousandSeparator(value,separator,toFixed) {
        separator = separator || '.';
        toFixed = toFixed || 0;
        return value.toFixed(toFixed).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1"+separator);
    }
    function postAnalitycsRequest(select_analytic_period,postPath,redirect,snapshoot){
        $("#flash").show();
        if (postPath == null){
            postPath = window.location.pathname;
        }
        $.ajax({
            type: "POST",
            headers: {
                'X-CSRF-Token': $('meta[name="_token"]').attr('content')
            },
            url: postPath,
            data: {
                'select_analytic_period': select_analytic_period
            },
            success: function(msg){
                $("#chartsContainer").html(msg);
                $("#flash").hide();
                if (redirect !== null && select_analytic_period == 60){
                    $.get(snapshoot, function( data ) {
                        $(window).attr('location', redirect )
                    });
                }
            },
            error: function(msg){
                $("#flash").hide();
            }
        })
    }
//Script for init follow button
    function followInitializate(elementId, followed, authorized, organisationId, followText, unFollowText,unFollowHover, url , openModal){
        var follow = new Vue({
            el: elementId,
            data: {
                follow: followed,
                authorized: authorized,
                loading: false,
                organisationId: organisationId,
                followText: followText,
                unFollowText: unFollowText,
                currentFollowText:'',
            },
            created: function () {
                this.currentFollowText = this.follow ? this.unFollowText : this.followText;
            },
            methods:{
                sendFollow:function(){
                    if(this.authorized===true){
                        this.loading = true;
                        if(this.follow ===true){
                            this.follow = false;
                        }
                        axios.post(url, {
                            organisationId : this.organisationId
                        })
                            .then(function(response){
                                if(response.data===false){
                                    follow.follow = false;
                                    follow.currentFollowText = follow.followText;
                                }else{
                                    follow.follow = true;
                                    follow.currentFollowText=follow.unFollowText;
                                }
                                follow.loading = false;
                            })
                            .catch(function(error){
                                follow.loading = false;
                                follow.follow = false;
                                follow.currentFollowText = follow.followText;
                            })
                    }else{
                        document.getElementById('buttonOpenLoginModal').click();
                    }
                },
                writeFollowText:function(){
                    if(follow.follow===true){
                        this.currentFollowText = unFollowHover;
                    }
                },
                writeUnFollowText:function () {
                    if(follow.follow===true) {
                        this.currentFollowText = this.unFollowText;
                    }else{
                        this.currentFollowText = this.followText;
                    }
                }
            }
        })
    }
    function showHideUsefulButtonIf0(element){
        if(parseInt(element.text()) === 0){
            element.parent().hide();
        }else{
            element.parent().show();
        }
    }
    function initializeUseful(componentId,checked,url ,authorized ,openModal, parentDiv){
        parentDiv = parentDiv || null;
        var usefull = new Vue({
            el: "span[vueid='"+componentId+"']",
            data: {
                checked:checked,
                authorized: authorized,
                loading: false,
                componentId: componentId
            },
            methods:{
                saveUseful:function(){
                    if(this.authorized===true){
                        this.loading=true;
                        var current=this;
                        axios.post(url, {
                                componentId: this.componentId
                            })
                            .then(function(response){
                                current.loading = false;
                                current.checked = response.data.checked;
                                if(parentDiv===null){
                                    $("span[vueCountId='"+componentId+"']").text(response.data.totalCount);
                                    showHideUsefulButtonIf0($("span[vueCountId='"+componentId+"']"));
                                }else{
                                    $(parentDiv).find("span[vueCountId='"+componentId+"']").text(response.data.totalCount);
                                    showHideUsefulButtonIf0($(parentDiv).find("span[vueCountId='"+componentId+"']"));
                                }
                            })
                            .catch(function(error){
                                current.loading = false;
                            })
                    }else{
                        document.getElementById('buttonOpenLoginModal').click();
                    }
                }
            }
        });
    }
    function iosButton(element,changeUrl, isActive , onOffButtonId, sendValue, checkboxElement){
        $(element).css('width','100%');
        $(element).css('float','left');
        $(element).find('label').css("float",'left');
        $(element).find('label').css("width",'75%');
        sendValue = sendValue || null;
        checkboxElement = checkboxElement || null;
        new Vue({
            el:element,
            data: {
                show: isActive
            },
            methods: {
                updateOnOrOFf: function(event) {
                    if($(onOffButtonId).attr("disabled")===false || typeof $(onOffButtonId).attr("disabled")==='undefined'){
                        $(onOffButtonId).attr("disabled", true);
                        var current = this;
                        var valuesToServer = sendValue !== null ? { value : sendValue } : {};
                        axios.post(changeUrl, valuesToServer)
                        .then(function(response){
                            current.show=response.data;
                            $(onOffButtonId).attr("disabled", false);
                        }).catch(function(error){
                            $(onOffButtonId).attr("disabled", false);
                        })
                    }else{
                        event.stopPropagation();
                    }
                },
                onOffCheckbox: function (event) {
                    if(checkboxElement !== null){
                        this.show = !this.show;
                        checkboxElement.val(this.show === true ? '1' : '0');
                    }
                }
            }
        });
    }

    function deleteElementWithAlert(idDelete,elementDelete,deleteUrl,deleteAttentionText,confirmButtonText,cancelButtonText){
        deleteAttentionText = deleteAttentionText || "";
        confirmButtonText = confirmButtonText || "";
        cancelButtonText = cancelButtonText || "";
        swal({
                title: deleteAttentionText,
                type: "info",
                showCancelButton: true,
                confirmButtonText : confirmButtonText,
                cancelButtonText : cancelButtonText,
                closeOnConfirm: false,
                showLoaderOnConfirm: true,
            },
            function(){
                axios.post(deleteUrl, {
                        id: idDelete
                    })
                    .then(function(){
                        $(elementDelete).remove();
                        swal.close();
                    })
                    .catch(function(error){
                        swal.close();
                    })
            });
    }
    function deleteElementWithAlertWithOutServerSide(idDelete,deleteAttentionText,confirmButtonText,cancelButtonText){
        deleteAttentionText = deleteAttentionText || "";
        confirmButtonText = confirmButtonText || "";
        cancelButtonText = cancelButtonText || "";
        swal({
                title: deleteAttentionText,
                type: "info",
                showCancelButton: true,
                confirmButtonText : confirmButtonText,
                cancelButtonText : cancelButtonText,
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            },
            function(){
                idDelete.remove();
                swal.close();
            });
    }
    function custom_editor_account(editor){
        CKEDITOR.config.youtube_width = '100%';
        CKEDITOR.config.youtube_height = '420';
        CKEDITOR.config.removePlugins = 'devtools, dialogadvtab, bidi, colordialog, templates, div, resize, elementspath, find, flash, forms, format, horizontalrule, htmlwriter, iframe,  indentblock, smiley, liststyle, magicline, maximize, newpage, pagebreak,  preview, print, removeformat, save, selectall, showblocks, showborders, sourcearea, specialchar, scayt, stylescombo, tab, wsc, xml, ajax, backgrounds, divarea, imagebrowser, oembed, mediaembed, qrc, slideshow, stylesheetparser, tableresize, texttransform, uicolor, iframedialog, a11yhelp, backup, language, about';
        CKEDITOR.config.skin = 'moono';
        CKEDITOR.config.fontSize_sizes = '10/10px;12/12px;14/14px;16/16px;18/18px;';
        CKEDITOR.config.font_names = 'Arial/Arial';
        CKEDITOR.replace ( editor,{
            toolbar: [
                ['FontSize','TextColor', 'BGColor','Bold','Italic','Underline','Strike', 'Subscript', 'Superscript','-','NumberedList','BulletedList','Outdent','Indent','Blockquote','-','Format',]
                    ['JustifyLeft','JustifyCenter','JustifyRight', 'JustifyBlock','-','Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']
                    ['Table','-','Image','Youtube','-','Link','Unlink']
            ],
            height: 350
        });
    }
function newsSetZoomImage(elementBlock){
    $.each(elementBlock.find('img'),function (index,value) {
        $(value).wrap('<a href="' + $(value).attr('src') + '" data-lightbox="images"></a>');
    });
}
function autoCompleteElement(element,url){
    element.typeahead({ source:function (query, process) {
        if(query.length>=2){
            return $.getJSON(
                url,
                {value: query},
                function (data) {
                    return process(data);
                });
        }
    }});
}
//extractor for autocompleteelementmultiselect
function extractor(query) {
    var result = /([^,]+)$/.exec(query);
    if(result && result[1])
        return result[1].trim();
    return '';
}
function autoCompleteElementMultiSelect(element , url){
    element.typeahead({
        source:function (query, process) {
            query = query.split(',').pop();
            if(query.length>=2){
                return $.getJSON(
                    url,
                    {value: query},
                    function (data) {
                        return process(data);
                    });
            }},
        updater: function(item) {
            return this.$element.val().replace(/[^,]*$/,'')+item+',';
        },
        matcher: function (item) {
            var tquery = extractor(this.query);
            if(!tquery) return false;
            return ~item.toLowerCase().indexOf(tquery.toLowerCase())
        },
        highlighter: function (item) {
            var query = extractor(this.query).replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&')
            return item.replace(new RegExp('(' + query + ')', 'ig'), function ($1, match) {
                return '<strong>' + match + '</strong>'
            })
        }
    });
}
    function set_votes_b(widget) {
        var val = $(widget).find('.rating_value').val();
        $(widget).find('.star_' + val).prevAll().addBack().addClass('ratings_vote_b');
        $(widget).find('.star_' + val).nextAll().removeClass('ratings_vote_b');

    }
    function set_votes(widget) {
        var val = $(widget).find('.rating_value').val();
        $(widget).find('.star_' + val).prevAll().addBack().addClass('ratings_vote');
        $(widget).find('.star_' + val).nextAll().removeClass('ratings_vote');
    }
function scrollToElement(element){
    if ($(element) && $(element).length) {
        if ($(window).scrollTop() > $(element).first().offset().top) {
            $('html, body').animate({
                scrollTop: ($(element).first().offset().top-70)
            },200);
        }else{
            $('html, body').animate({
                scrollTop: ($(element).first().offset().top)
            },200);
        }
    }
}
function validate(htmlInput,isRadio ,minLength ,maxLength){
    isRadio = isRadio || false;
    minLength = minLength || 1;
    maxLength = maxLength || null;
    var result = true;
    if(isRadio === true && !htmlInput.is(':checked')){
        result = false;
    }
    if(maxLength!==null && htmlInput.val().length>maxLength){
        result = false;
    }
    if(htmlInput.val() === 'undefined' || htmlInput.val().length<minLength){
        result = false;
    }
    if(result === false){
        htmlInput.parent().addClass('has-error');
        htmlInput.parent().find('[name=required]').show();
    }
    return result;
}
var touch = 'ontouchstart' in document.documentElement
    || navigator.maxTouchPoints > 0
    || navigator.msMaxTouchPoints > 0;

if (touch) { // remove all :hover stylesheets
    try { // prevent exception on browsers not supporting DOM styleSheets properly
        for (var si in document.styleSheets) {
            var styleSheet = document.styleSheets[si];
            if (!styleSheet.rules) continue;

            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                if (!styleSheet.rules[ri].selectorText) continue;

                if (styleSheet.rules[ri].selectorText.match(':hover')) {
                    styleSheet.deleteRule(ri);
                }
            }
        }
    } catch (ex) {}
}

function copyToClipboard(element,copiedText) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).attr('url')).select();
    document.execCommand("copy");
    $("[name=copied]").remove();
    $(element).parent().append("<span class='text-success copied-text' name='copied'><i class='fa fa-check'></i>"+copiedText+"</span>");
    $("[name=copied]").fadeOut(1000);
    $temp.remove();
}

function initUsefulButtons(url, withParent, parentDiv){
    withParent = withParent || false;
    parentDiv = parentDiv || "";
    var openModal=$('#buttonOpenLoginModal');
    if(withParent === false) {
        $.each($('span[vueid]'), function (index, value) {
            var componentId = value.attributes.vueid.value;
            var checked = (value.attributes.ischecked.value === "true");
            initializeUseful(componentId, checked, url, authorized, openModal);
        });
    }else{
        $.each($(parentDiv).find('span[vueid]'), function (index, value) {
            var componentId = value.attributes.vueid.value;
            var checked = (value.attributes.ischecked.value === "true");
            initializeUseful(componentId, checked, url, authorized, openModal,parentDiv);
        });
    }
}

function initStarts(value,nameClass,addClass) {
    var stat = set_status($(value).val());
    $(value).parent().find('.msg_rating').text(stat);
    $(value).parent().find(nameClass+':nth-child('+$(value).val()+')').prevAll().addBack().addClass(addClass);
}

function clearLocalStorageByKeyMatch(prefix) {

    // Find items in the array where he key contains the word 'word'
    var keys = Object.keys(localStorage);
    for (i = 0; i < keys.length; i++) {
        if (keys[i].indexOf(prefix) === 0) {
            localStorage.removeItem(keys[i]);
        }
    }

}
function employerStatus(status){
    $('[name=employer_status]').on('click',function () {
        var value = $('[name=employer_status]:checked').val();
        if(value === status){
            $("#last_year_e").show();
        }else{
            $("#last_year_e").hide();
        }
    });
}

function submitOrganisationProfileForms(buttonSubmit , formSubmit , responseMsg){
    responseMsg = responseMsg || $('#response-msg');
    buttonSubmit.on('click',function () {
        formSubmit.submit();
    });
    formSubmit.ajaxForm({
        beforeSend: function() {
            buttonSubmit.prop('disabled',true);
        },
        success:function (success) {
            responseMsg.hide();
            buttonSubmit.prop('disabled',false);
            swal({
                position: 'top-end',
                type: 'success',
                title: success,
                showConfirmButton: false,
                timer: 2000
            });
        },
        error: function(error){
            buttonSubmit.prop('disabled',false);
            responseMsg.addClass('alert alert-danger').removeClass('alert-success');
            var response = JSON.parse(error.responseText);
            responseMsg.html("");
            responseMsg.show();
            $.each(response, function(key, value) {
                $('#response-msg').append(value[0]+"<br>");
            });
            scrollToElement($('#response-msg'));
        }
    });
}
function htmlEncode(value){
    return $('<div/>').text(value).html();
}
function htmlDecode(value){
    return value.replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}
function showSuccessSwallMsg(message){
    swal({
        position: 'top-end',
        type: 'success',
        title: message,
        showConfirmButton: false,
        timer: 2000
    });
}
function readURL(input,croppieWindow) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            croppieWindow.croppie('bind', {
                url: e.target.result
            });
        };
        reader.readAsDataURL(input.files[0]);
    }
}
function imageSelectUl(resultDiv){
    $('[imageSelectUl]').change(function(e){
        var fileName = e.target.files[0].name;
        resultDiv.text(fileName);
    });
}
function resizeDivProportion(div, width, height){
    function resizeDivSubFunction(){
        var percentWidth = (div.width() * 100) / width;
        div.height((height * percentWidth) / 100);
    }
    $(window).resize(resizeDivSubFunction);
    resizeDivSubFunction()
}
function addWishlistJob(element,url){
    element.on('click', function (event) {
        if (authorized === true) {
            var thisButton = $(this);
            if (thisButton.attr("disabled") === false || typeof thisButton.attr("disabled") === 'undefined') {
                thisButton.attr("disabled", true);
                thisButton.removeClass('fa-heart-o fa-heart').addClass('fa-spin fa-spinner');
                axios.post(url, {
                    job_id: thisButton.attr('id')
                })
                    .then(function (response) {
                        thisButton.attr("disabled", false);
                        if (response.data.success) {
                            dataLayer.push({'event': 'addWishlistJob'});//don't touch
                            thisButton.removeClass('fa-spin fa-spinner').addClass('fa-heart');
                        } else {
                            thisButton.removeClass('fa-spin fa-spinner').addClass('fa-heart-o');
                        }
                    }).catch(function (error) {
                    thisButton.attr("disabled", false);
                    thisButton.removeClass('fa-spin fa-spinner').addClass('fa-heart');
                })
            } else {
                event.stopPropagation();
            }
        } else {
            document.getElementById('buttonOpenLoginModal').click();
        }
    });
}
function checkIfIsAuthorized(msgResponse){
    function showAuthorizedSwall(msgResponse){
        swal({
            position: 'top-end',
            type: 'info',
            title: msgResponse,
            showConfirmButton: true
        });
    }
    axios.interceptors.response.use(function(response) {
        return response;
    }, function (error) {
        // Do something with response error
        if (error.response.status === 401) {
            showAuthorizedSwall(msgResponse);
        }
        return Promise.reject(error.response);
    });
    $.ajaxSetup({
        error: function (x, status, error) {
            if (x.status === 403) {
                showAuthorizedSwall(msgResponse);
            }
        }
    });
}
//checkIfIsAuthorized('Sesiune expirata, intra in cont.');